import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Update from 'immutability-helper';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Layout from 'components/Layout';
import TankTempData from 'components/Dashboard/TankTempData';
import Gauge from 'components/Dashboard/Gauge';
import { DataLoadingStatusEnum } from 'components/Dashboard/enums';
import TempGraph from 'components/Dashboard/TempGraph';
import CountData from 'components/Dashboard/CountData';
import MaximumDemandGraph from 'components/Dashboard/MaximumDemandGraph';


const apiUrl = 'http://whiskey.hopto.org:8080/api/log';
const unit = '172.28.15.122';

class TestDashboard extends Component {
  constructor(props) {
    super(props);
    this.location = props.location;
    this.state = {
      tempData: [],
      tempDataLoadingStatus: DataLoadingStatusEnum.LOADING,
      countData: [],
      countDataLoadingStatus: DataLoadingStatusEnum.LOADING,
      from: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DDTHH:MM'),
    };
    this.pageTitle = this.location ? this.location.pathname.replace(/\//g, '') : '';
  }

  render() {
    return (
      <Layout location={this.location} title={this.pageTitle}>
        <Helmet>
          <title>{'Powersave - ' + this.pageTitle}</title>
        </Helmet>
        <Grid container spacing={4} alignItems="center" justify="center" direction="row" >
          <Grid item xs={5}>
            <Gauge
              name="Main Tank"
              chartData={this.state.tempData}
              dataLoadingStatus={this.state.tempDataLoadingStatus}
              valueIndex={1}
            />
          </Grid>
          <Grid item xs={5}>
            <Gauge
              name="Ringmain Return"
              chartData={this.state.tempData}
              dataLoadingStatus={this.state.tempDataLoadingStatus}
              minOptimumTemp={50}
              valueIndex={2}
            />
          </Grid>
          <Grid item xs={12}>
            <TempGraph chartData={this.state.tempData} dataLoadingStatus={this.state.tempDataLoadingStatus} />
          </Grid>
          <Grid item xs={12}>
            <MaximumDemandGraph chartData={this.state.countData} dataLoadingStatus={this.state.tempDataLoadingStatus} />
          </Grid>
        </Grid>

        <TankTempData
          dataLoadedCallback={this.tempDataCallback}
          dataHeaders={['Time', 'Main Tank', 'Ringmain Return']}
          apiUrl={apiUrl}
          unit={unit}
          from={this.state.from}
          to={this.state.to}
        />

        <CountData
          dataLoadedCallback={this.countDataCallback}
          apiUrl={apiUrl}
          unit={unit}
          dataKey={'count1'}
          interval={10}
          from={this.state.from}
          to={this.state.to}
        />
      </Layout>
    );
  }

  tempDataCallback = (chartData, dataLoadingStatus) => {
    this.setState(previous =>
      Update(previous, {
        tempData: { $set: chartData },
        tempDataLoadingStatus: { $set: dataLoadingStatus },
      })
    );
  };

  countDataCallback = (chartData, dataLoadingStatus) => {
    this.setState(previous =>
      Update(previous, {
        countData: { $set: chartData },
        countDataLoadingStatus: { $set: dataLoadingStatus },
      })
    );
  };

  dateChangedCallback = dateRange => {
    this.setState(previous =>
      Update(previous, {
        from: {
          $set: moment()
            .subtract(dateRange, 'days')
            .format('YYYY-MM-DD'),
        },
      })
    );
  };
}

export default TestDashboard;
